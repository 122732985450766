<template>
  <div>
    <div class="pt-banner">
      <img src="@/assets/images/pintuan_images/top@2x.png" alt="" />
    </div>
    <div class="pt-wrapper">
      <div class="container">
        <div class="pt-bk">
          <el-row :gutter="52">
            <el-col :span="12">
              <a href="#/activity" class="bk-cell">
                <img src="../../assets/images/pintuan_images/c1.png" alt="" />
              </a>
            </el-col>
            <el-col :span="12">
              <a
                :href="'#/groupBuyDetail?activeId=' + groupBuyActiveId"
                class="bk-cell"
              >
                <img src="../../assets/images/pintuan_images/c3.png" alt="" />
              </a>
            </el-col>
          </el-row>
        </div>
        <div class="pt-panel">
          <div class="pt-panel_hd">
            <img src="../../assets/images/pintuan_images/tt1.png" alt="" />
          </div>
          <div class="pt-panel_body">
            <el-row>
              <el-col
                :span="12"
                v-for="(item, index) in preferentialGiveList"
                :key="index"
              >
                <div class="pt-card pt-card-red" @click="buyCombo(item)">
                  <div class="pt-inner">
                    <div class="pt-photo">
                      <img :src="item.cover" alt="" />
                    </div>
                    <div class="pt-down">
                      <div class="d-txt">直降</div>
                      <div class="d-numner">${{ item.subsidyPrice }}</div>
                    </div>
                    <div class="pt-grid">
                      <div class="pt-title" :class="{ active: index }">
                        {{ item.buyComboName }}
                      </div>
                      <div class="pt-tags">
                        <el-tag effect="plain">零基础</el-tag>
                        <el-tag type="warning" effect="plain">必 考</el-tag>
                      </div>
                      <div class="pt-price-cell">
                        <span class="text">优惠价</span>
                        <span class="price-v"
                          >${{ item.currentPrice
                          }}<span class="price-del"
                            ><i class="del"
                              >/原价${{ item.originalPrice }}</i
                            ></span
                          ></span
                        >
                      </div>
                      <div class="pt-zeng" v-if="item.giveCourseName">
                        <span class="z-icon"></span>
                        <span class="text"
                          >{{ item.giveCourseName }}
                          {{ item.complimentaryDay }}天</span
                        >
                        <span class="z-gift"></span>
                      </div>
                    </div>
                  </div>
                  <div class="pt-icon-basic"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="pt-panel">
          <div class="pt-panel_hd">
            <img src="../../assets/images/pintuan_images/tt2.png" alt="" />
          </div>
          <div class="pt-list_body">
            <div
              class="pt-list-card flex flex-ac"
              v-for="(item, index) in groupBuyList"
              :key="index"
            >
              <div class="pt-card-pic"><img :src="item.cover" alt="" /></div>
              <div class="flex_bd">
                <div class="pt-heading">
                  <div class="pt-title">【 {{ item.buyComboName }} 】</div>
                  <el-tag type="warning" effect="plain"
                    >{{ item.duration }}天有效期</el-tag
                  >
                </div>
                <div class="pt-info">
                  <span>{{ item.limitNum }}</span>
                  <div class="pt-2-icon"></div>
                  <div class="pt-price-cell">
                    <span class="price-v">${{ item.currentPrice }}</span>
                    <span class="price-del"
                      ><i class="del">/原价${{ item.originalPrice }}</i></span
                    >
                  </div>
                </div>
              </div>
              <router-link
                :to="{
                  path: '/groupBuyDetail',
                  query: { activeId: groupBuyActiveId },
                }"
                class="pt-button"
              >
                <el-button type="pt-button" style="color: #fff"
                  >点击参与拼团</el-button
                >
              </router-link>
              <div class="pt-down">
                <div class="d-txt">直降</div>
                <div class="d-numner">${{ item.subsidyPrice }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="pt-panel pt-panel-last">
          <div class="pt-panel_hd">
            <img src="../../assets/images/pintuan_images/tt3.png" alt="" />
          </div>
          <div class="pt-panel_body">
            <div class="recom-panel">
              <div class="exm-row flex flex-zBetween">
                <div class="exm-left">
                  <div class="pt-heading">
                    <div class="pt-title">【 LeetCode 千题讲解 】</div>
                    <el-tag type="warning" effect="plain">30 天</el-tag>
                  </div>
                  <div class="pt-card-pic">
                    <img
                      src="../../assets/images/pintuan_images/pic-lg1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="exm-flex_bd">
                  <div class="exm-firs-div">
                    <img
                      src="../../assets/images/pintuan_images/next-txt.png"
                      alt=""
                    />
                  </div>
                  <div class="exm-div">
                    <img
                      src="../../assets/images/pintuan_images/time-tt.png"
                      alt=""
                    />
                  </div>
                  <div class="exm-time-group flex flex-ac">
                    <mv-count-down
                      @startCallback="countDownS"
                      @endCallback="countDownE"
                      :startTime="startTime"
                      :endTime="endTime"
                      :endText="endText"
                      :dayTxt="'天'"
                      :hourTxt="':'"
                      :minutesTxt="':'"
                      :secondsTxt="':'"
                      :isStart="isStart"
                    ></mv-count-down>
                  </div>
                  <el-button type="pt-button" @click="dialogFormVisible = true"
                    >点击参与秒杀</el-button
                  >
                </div>
                <div class="exm-right">
                  <div class="exm-days">
                    <img
                      src="../../assets/images/pintuan_images/daytxt.png"
                      alt=""
                    />
                  </div>
                  <div class="exm-nn">
                    <img
                      src="../../assets/images/pintuan_images/3m.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="exm-cells">
                <div class="exm-cell">
                  <div class="hd">
                    <img
                      src="../../assets/images/pintuan_images/ms-txt.png"
                      alt=""
                    />
                  </div>
                  <div class="flex_bd">
                    <div class="exm-lg">提前登录 - 准点秒杀 - 秒杀成功</div>
                  </div>
                </div>
                <div class="exm-cell">
                  <div class="hd">
                    <img
                      src="../../assets/images/pintuan_images/gz-text.png"
                      alt=""
                    />
                  </div>
                  <div class="flex_bd">
                    <div class="exm-cont">
                      <p>1、活动时间：2021年6月16日--2021年6月18日</p>
                      <p>2、场次：每天10:00 ，0元秒杀</p>
                      <p>
                        3、参与商品：LeetCode题目视频讲解课程<1-1000题 >
                        有效期30天
                      </p>
                      <p>
                        4、参与方式：从CSON618专场活动页面点击秒杀专区查看详情
                      </p>
                      <p>5、奖品将在活动结束后15-20个工作日内发放</p>
                      <p>
                        6、温馨提示：请用户在活动开始前及时完成注册，否则秒杀成功后无法发放课程奖励
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- div.gift -->
    <div class="gift-fixed" @click="visible = !visible">
      <div class="box-popover" v-if="visible">
        <div class="inner">
          <img src="../../assets/images/pintuan_images/wxc.png" />
          <div class="txt1">扫描微信二维码<br />课程和网站疑问</div>
          <div class="txt2">针对优惠有疑问?<br />购买时遇到问题?</div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      width="420px"
      custom-class="pt-dialog"
      :visible.sync="dialogFormVisible"
    >
      <div class="dialog-success">
        <div class="log-head">
          <span class="log-tit">黑五 0元秒杀风暴</span>
        </div>

        <div class="log-body">
          <span
            >扫码快速参与「618黑五秒杀风暴」<br />
            拼手速，千元课程0元秒杀</span
          >
        </div>
        <div class="log-qrcode">
          <img src="../../assets/images/pintuan_images/qrcode.png" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MvCountDown from "@/components/MvCountDown/MvCountDown";
import { getAcitve, getEndTime, shareCount } from "../../service/index.js";
import { setStorage, setStorageForArray } from "../../utils/common";

export default {
  components: { MvCountDown },
  data() {
    return {
      visible: false,
      visible2: true,
      dialogFormVisible: false,
      // 倒计时
      startTime: new Date().getTime(), // 开始时间（时间戳）
      endTime: 0, // 完成的时间（时间戳）
      endText: "活动结束", // 倒计时完成的提示文本
      isStart: false, // 控制倒计时开始的时机（异步请求完成开启）
      preferentialGiveList: [],
      groupBuyList: [],
      preferentialGiveActiveId: 0,
      groupBuyActiveId: 0,
    };
  },
  created() {
    //;
    getEndTime().then((res) => {
      if (res.success) {
        this.setEndTime(res.result);
      }
    });
    // console.log(this.startTime);
    console.log(1111);
  },
  mounted() {
    window.scrollTo(0, 0);
    this.preferentialGive();
    this.groupBuy();
  },
  methods: {
    buyCombo(item) {
      console.log(item);
      let cart = {
        imgUrl: item.cover,
        objectName: item.buyComboName,
        objectId: item.comboId,
        classId: item.courseClassId,
        price: item.currentPrice,
        realPrice: item.originalPrice,
        comboId: item.comboId,
        activeId: item.id,
      };

      setStorage("totalprice", item.currentPrice || 0);
      setStorage("realPrice", item.originalPrice || 0);
      setStorageForArray("orderSelect", [cart]);

      this.$router.push("/confirm?type=1");
    },
    groupBuy() {
      getAcitve(2).then((res) => {
        if (res.success) {
          this.groupBuyActiveId = res.result.activeId;
          this.groupBuyList = res.result.activeDetails;
          shareCount({
            type: 1,
            activeId: this.groupBuyActiveId,
          });
        }
      });
    },
    preferentialGive() {
      console.log(222);
      getAcitve(1).then((res) => {
        if (res.success) {
          this.preferentialGiveActiveId = res.result.activeId;
          this.preferentialGiveList = res.result.activeDetails;
          shareCount({
            type: 1,
            activeId: this.preferentialGiveActiveId,
          });
          console.log(this.preferentialGiveList);
          // let temp1 = [];
          // for (let i = 0; i < temp.length; i++) {
          //   // console.log(temp[i].substr(1, 7));
          //   for (let j = i + 1; j < temp.length; j++) {
          //     if (temp[i].comboId == temp[j].comboId) {
          //       if (temp[i].type.value == 0) {
          //         temp[i].originalPrice = temp[j].originalPrice;
          //         temp[i].currentPrice = temp[j].currentPrice;
          //         temp[i].subsidyPrice = temp[j].subsidyPrice;
          //         temp[i].cover = temp[j].cover;
          //       } else if (temp[i].type.value == 3) {
          //         temp[i].giveCourseName = temp[j].giveCourseName;
          //         temp[i].complimentaryDay = temp[j].complimentaryDay;
          //       }
          //       console.log(1111);
          //       temp1.push(temp[i]);
          //     }
          //   }
          // }
          // console.log(temp1);
          //
          // let temp2 = [];
          // for (let i = 0; i < temp1.length; i++) {
          //   let tag = true;
          //   for (let j = 0; j < temp2.length; j++) {
          //     if (temp2[j].comboId == temp1[i].comboId) {
          //       tag = false;
          //     }
          //   }
          //   if (tag) {
          //     console.log(temp1[i]);
          //     temp2.push(temp1[i]);
          //   }
          // }
          //
          // this.preferentialGiveList = temp2;
          // console.log(this.preferentialGiveList);
        }
      });
    },
    setEndTime(value) {
      // let curTime = new Date();
      // let newTime = new Date(curTime.setSeconds(curTime.getSeconds() + value));
      this.endTime = value;
      this.isStart = true;
    },
    countDownS(x) {
      // 开始倒计时回调
      console.log(x);
    },
    countDownE() {
      // 结束倒计时回调
      this.callback();
    },
  },
};
</script>

<style>
@import url("../../assets/font/YouSheBiaoTiHei.css");
@import url("../../assets/font/SourceHanSansRegular.css");

.gift-fixed {
  position: fixed;
  top: 50%;
  right: 10px;
  z-index: 1999;
  width: 166px;
  height: 181px;
  cursor: pointer;
  background: url(../../assets/images/pintuan_images/liwu.png) no-repeat;
}

.pt-dialog {
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/pintuan_images/pp-bg.png);
  background-size: cover;
}

.gift-fixed .box-popover {
  background: url(../../assets/images/pintuan_images/popover.png) no-repeat;
  width: 120px;
  height: 180px;
  position: absolute;
  bottom: 160px;
  left: 50%;
  margin-left: -60px;
}

.box-popover {
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
}

.box-popover .inner {
  padding: 15px 0;
}

.box-popover .txt1 {
  color: #fa6400;
  margin-top: 5px;
}
.dialog-success .log-head {
  margin-bottom: 20px;
  text-align: center;
}
.dialog-success .log-body {
  text-align: center;
}
.dialog-success .log-qrcode {
  text-align: center;
}
.dialog-success .log-qrcode img {
  margin: 20px;
}
.dialog-success .log-head .log-tit {
  display: inline-block;
  background-color: #ff5247;
  padding: 0 20px;
  line-height: 26px;
  color: #fff;
  font-size: 16px;
}
.box-popover .txt2 {
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 1600px) {
  .gift-fixed {
    left: 50%;
    margin-left: 550px;
  }
}

.exm-flex_bd {
  padding-top: 10px;
}

.exm-time-group {
  margin-bottom: 15px;
}

.exm-time-group .nn {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 58px;
  line-height: 58px;
  color: #fff;
  text-align: center;
  background: #fe5e4a;
  font-size: 40px;
  border-radius: 4px;
  font-family: YouSheBiaoTiHei;
}

.exm-time-group .fm {
  display: inline-block;
  width: 32px;
  text-align: center;
  height: 55px;
  font-size: 42px;
  font-family: YouSheBiaoTiHei;
  color: #fe5e4a;
  line-height: 42px;
}

.exm-time-group .nn::after {
  position: absolute;
  display: block;
  content: "";
  height: 4px;
  left: 2px;
  right: 2px;
  bottom: -4px;
  background: #ffd155;
  border-radius: 0px 0px 8px 8px;
}

.exm-firs-div {
  margin-bottom: 10px;
}

.exm-div {
  margin-bottom: 5px;
}

.flex-zBetween {
  justify-content: space-between;
}

.exm-cells {
  padding: 60px 100px 0 100px;
}

.exm-cell {
  display: flex;
}

.exm-cell:first-child {
  margin-bottom: 35px;
}

.exm-cell .hd {
  margin-right: 25px;
  font-size: 0;
}

.exm-lg {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #f95959;
  line-height: 20px;
}

.exm-cont {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #f95959;
}

.pt-list_body {
  padding: 60px 120px 40px 120px;
}

.recom-panel {
  position: relative;
  background: #fff7f7;
  margin-bottom: 20px;
  border-radius: 8px;
  line-height: 1.2;
  padding: 50px;
}

.recom-panel::after {
  position: absolute;
  display: block;
  content: "";
  height: 6px;
  left: 2px;
  right: 2px;
  bottom: -4px;
  background: #fe591d;
  border-radius: 0px 0px 8px 8px;
}

.pt-list-card {
  position: relative;
  background: #fff7f7;
  margin-bottom: 20px;
  border-radius: 8px;
  line-height: 1.2;
  padding: 38px 30px;
}
.pt-down .d-txt {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 5px;
  color: #ffffff;
  text-shadow: 0px 2px 3px rgba(155, 0, 0, 0.5);
}

.pt-down .d-numner {
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-shadow: 0px 2px 3px rgba(155, 0, 0, 0.5);
}

.pt-list-card .pt-down {
  position: absolute;
  right: 90px;
  top: 5%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 45px;
  width: 98px;
  height: 113px;
  background: url(../../assets/images/pintuan_images/down.png);
}
.pt-button {
  position: absolute;
  right: 50px;
  bottom: 20%;
}
.pt-panel
  .pt-list_body
  .pt-list-card:nth-child(3)
  .flex_bd
  .pt-heading
  .pt-title {
  color: #8071f4 !important;
}

.pt-list-card::after {
  position: absolute;
  display: block;
  content: "";
  height: 6px;
  left: 2px;
  right: 2px;
  bottom: -4px;
  background: #fe591d;
  border-radius: 0px 0px 8px 8px;
}

.pt-card-pic {
  margin-right: 14px;
}

.pt-card-pic img {
  width: 234px;
  height: 133px;
}

.exm-left .pt-card-pic {
  margin-right: 0;
}

.exm-left .pt-card-pic img {
  width: 260px;
  height: 147px;
}

.pt-heading {
  display: flex;
  align-items: center;
  line-height: 1.2;
  margin-bottom: 32px;
}

.pt-heading .pt-title {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #ff352e;
  line-height: 27px;
}

.pt-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
  color: #fa6400;
  font-size: 18px;
  font-weight: bold;
}

.pt-2-icon {
  width: 150px;
  height: 44px;
  margin-right: 6px;
  background: url(../../assets/images/pintuan_images/pt-txt.png) no-repeat;
}
::v-deep .el-button,
::v-deep .el-button:focus,
::v-deep .el-button:hover {
  color: #fff !important;
}
.el-button--pt-button {
  padding: 0;
  border: 0;
  color: #fff;
  width: 180px;
  height: 30px;
  background: linear-gradient(141deg, #ff4e50 0%, #f9d423 100%);
  border-radius: 15px;
  border: 1px solid;
  text-shadow: 0px 1px 2px rgba(184, 0, 0, 0.5);
}

.pt-blue-title {
  color: #8071f4 !important;
}

/* 2222 */
.pt-list-title {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #fa6400;
  line-height: 27px;
}

.pt-time {
  height: 28px;
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ff352e;
  line-height: 42px;
  padding-left: 32px;
}

.pt-banner {
  position: relative;
}
.pt-banner img {
  vertical-align: top;
}
.pt-wrapper {
  padding-top: 30px;
  padding-bottom: 150px;
  background-image: linear-gradient(#ffefdb, #ffb381);
}

.pt-wrapper .container {
  width: 1108px;
}

.bk-cell {
  display: block;
  font-size: 0;
}

.pt-bk {
  margin-bottom: 50px;
}

.pt-panel {
  position: relative;
  margin-bottom: 50px;
  background-image: linear-gradient(#f84b48, #fffbe4);
  border-radius: 14px;
}

.pt-panel::before {
  content: "";
  width: 58px;
  height: 300px;
  position: absolute;
  left: 10px;
  top: 10px;
  background: url(../../assets/images/pintuan_images/cson1.png) no-repeat;
}

.pt-panel::after {
  content: "";
  width: 58px;
  height: 300px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: url(../../assets/images/pintuan_images/cson1.png) no-repeat;
}

.pt-panel-last::after {
  width: 98px;
  height: 134px;
  background: url(../../assets/images/pintuan_images/shandian.png) no-repeat;
}

.pt-panel_body {
  padding: 36px 120px;
}

.pt-panel_body > .el-row {
  padding-top: 30px;
}

.pt-panel_hd {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  text-align: center;
}

.pt-card {
  position: relative;
  margin: 0 auto;
  margin-bottom: 24px;
  width: 335px;
  position: relative;
  display: block;
  border-radius: 8px;
}

.pt-card .pt-down {
  position: absolute;
  right: -45px;
  top: -50px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 50px;
  width: 98px;
  height: 113px;
  background: url(../../assets/images/pintuan_images/down.png);
}

.pt-card .pt-inner {
  padding: 24px 44px;
}

.pt-card-red {
  background-color: #fff7f7;
}

.pt-card-blue {
  background-color: #f7fcff;
}

.pt-card-poplur {
  background-color: #f8f7ff;
}

.pt-card::after {
  position: absolute;
  display: block;
  content: "";
  height: 6px;
  left: 2px;
  right: 2px;
  bottom: -4px;
  background: #fe591d;
  border-radius: 0px 0px 8px 8px;
}

.pt-photo {
  margin-bottom: 5px;
}

.pt-photo img {
  width: 234px;
  height: 133px;
}

.pt-grid .pt-title {
  font-size: 18px;
  color: rgba(250, 100, 0, 1);
  margin-bottom: 5px;
  font-weight: bold;
  min-height: 54px;
}

/* ::v-deep .active {
  color: #49a8ea !important ;
}

.el-row .el-col:nth-child(4) .pt-card .pt-inner .pt-grid .pt-title {
  color: #7d72d2 !important;
} */

.pt-price-cell {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: flex-end;
  line-height: 1.2;
  font-weight: bold;
}

.pt-price-cell .text {
  color: #ff352e;
  font-size: 14px;
  margin-right: 6px;
}

.pt-price-cell .price-v {
  color: #ff352e;
  font-size: 26px;
}

.pt-price-cell {
  display: inline-block;
}
.pt-tags {
  margin-bottom: 12px;
}
.pt-tags .price-del {
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  margin-left: 10px;
}

.price-del .del {
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  margin-left: 10px;
  font-style: normal;
  text-decoration: line-through;
}

.pt-zeng {
  position: relative;
  width: 210px;
  border-radius: 30px;
  padding-left: 30px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 10px;
  line-height: 1;
  margin-top: 10px;
  margin-left: 10px;
  background-image: linear-gradient(to right, #ff4e50, #f9d423);
}

.pt-zeng .text {
  color: #ffffff;
  text-shadow: 0px 1px 2px rgba(184, 0, 0, 0.5);
}

.pt-zeng .z-icon {
  width: 35px;
  height: 48px;
  position: absolute;
  left: -10px;
  top: -12px;
  background: url(../../assets/images/pintuan_images/zeng.png);
}

.pt-zeng .z-gift {
  width: 41px;
  height: 36px;
  position: absolute;
  right: -12px;
  top: -8px;
  background: url(../../assets/images/pintuan_images/sm-gift.png);
}

.el-tag {
  margin-right: 10px;
  color: #0075f6;
  border-color: #0075f6;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 20px;
}

.el-tag--warning {
  background-color: #ffe1cc;
  color: #fa6400;
  border-color: #fa6400;
}

.pt-card-blue .pt-grid .pt-title {
  color: #4fabeb;
}

.pt-card-poplur .pt-grid .pt-title {
  color: #6356c7;
}

/* ad */
.btn-ad-fixed {
  position: fixed;
  width: 100%;
  z-index: 1999;
  bottom: 0;
  left: 0;
}

.close-x {
  position: absolute;
  right: 36px;
  top: 20px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  z-index: 89;
  background: url(../../assets/activity_images/pc/close.png) center no-repeat;
}

.btn-ad {
  width: 100%;
  height: 152px;
  cursor: pointer;
  background: url(../../assets/activity_images/pc/btn_ad.png) center no-repeat;
}

.flex {
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */
  display: -webkit-flex; /* 新版本语法: Chrome 21+ */
  display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
}

/* 主轴居中 */
.flex-hc {
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* 侧轴居中 */
.flex-ac {
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

/*充满父元素*/
.flex_bd {
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

.pt-icon-high {
  position: absolute;
  left: -2px;
  top: -1px;
  width: 97px;
  height: 97px;
  background: url(../../assets/images/pintuan_images/zhe-tag.png) no-repeat;
}

.pt-icon-basic {
  position: absolute;
  left: -2px;
  top: -1px;
  width: 97px;
  height: 97px;
  /* background: url(../../assets/images/pintuan_images/zhe-tag2.png) no-repeat; */
}
</style>
<style>
.exm-time-group .box span {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 58px;
  line-height: 58px;
  color: #fff;
  text-align: center;
  background: #fe5e4a;
  font-size: 40px;
  border-radius: 4px;
  font-family: YouSheBiaoTiHei;
  vertical-align: middle;
}

.exm-time-group .box .dw {
  display: inline-block;
  width: 32px;
  text-align: center;
  height: 58px;
  font-size: 42px;
  font-family: YouSheBiaoTiHei;
  color: #fe5e4a;
  line-height: 42px;
  vertical-align: middle;
}

.exm-time-group .box span::after {
  position: absolute;
  display: block;
  content: "";
  height: 4px;
  left: 2px;
  right: 2px;
  bottom: -4px;
  background: #ffd155;
  border-radius: 0px 0px 8px 8px;
}

.exm-time-group .box:last-child .dw {
  display: none;
}
</style>